import React from 'react';
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group';
import getTransitionStyle from '../helpers/getTransitionStyle';

const timeout = 300;

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props;
    return (
      <TransitionGroup>
        <ReactTransition
          appear
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {(status) => {
            return (
              <div
                style={
                  {
                    ...getTransitionStyle({
                      status,
                      timeout,
                    }),
                  }
                }
                className={
                  `fade fade-${status}`
                }
              >
                {children}
              </div>
            );
          }}
        </ReactTransition>
      </TransitionGroup>
    );
  }
}

export default Transition;
