const languages = ['en', 'fr'];
const catalogs = {
  en: { messages: require('./locale/en/messages.json') },
  fr: { messages: require('./locale/fr/messages.json') },
};

const defaultLanguage = 'en';

const prefix = lang => (lang === defaultLanguage ? '' : `/${lang}`);
const deprefix = pathname => (pathname.startsWith('/fr/') ? pathname.substr(4) : pathname);
const langFromPath = pathname => (pathname.startsWith('/fr/') ? 'fr' : 'en');

exports.defaultLanguage = defaultLanguage;
exports.languages = languages;
exports.catalogs = catalogs;
exports.prefix = prefix;
exports.deprefix = deprefix;
exports.langFromPath = langFromPath;
